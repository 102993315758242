@import "../src/color.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

body {
  font-family: serif;
}

.text-right {
  text-align: right;
}

.min-height {
  min-height: 100vh;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.border-radius {
  border-radius: 5px;
}

.w-80 {
  width: 80%;
}

.text-decoration-none {
  text-decoration: none !important;
}

/* theme color start */
.base-color {
  background: var(--green);
}

.secondary-color {
  background: var(--yellow);
}

.ticket-head-green-yellow {
  background: var(--green);
  border: 1px solid var(--yellow);
}

/* theme color end */
.mbl-text h5 {
  font-size: 12px;
  font-weight: 900;
}

.mbl-text img {
  height: 18px;
}

.ticket-head {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 11px;
  color: var(--white);
}

.ticket-num {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 1px 2px 9px 0px;
  width: 10%;
  margin: 0.5%;
}

.board-num {
  text-align: center;
  border: 1px solid;
  padding: 12px;
  border-radius: 5px;
  width: 10%;
  font-weight: 900;
  background-color: var(--darkGreen);
  color: var(--white);
}

.declared-num {
  text-align: center;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  border-radius: 50%;
  box-shadow: 1px 2px 9px 0px;
  width: 10%;
  margin: 0.5%;
  background: var(--red);
  color: var(--white);
}

.select-num {
  text-align: center;
  border: 1px solid;
  height: 6vh;
  padding: 4px;
  border-radius: 50%;
  box-shadow: 1px 2px 9px 0px;
  width: 10%;
  background: var(--white);
  color: var(--black);
}

.board-spacing {
  padding: 10px 22px;
}

.static-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 8vh;
}

.mt-8-vh {
  margin-top: 8vh;
}

.header-bg-color {
  background-color: var(--black);
}

.form-bg {
  height: 100vh;
  background-size: cover;
  background-position: 50%;
}

.main-headings h3 {
  border-bottom: 2px solid;
  color: var(--red);
}

/* //responsive design */
@media (min-width: 320px) and (max-width: 768px) {
  .dropdown button {
    margin-bottom: 10px;
    text-align: center;
  }

  .user-table {
    text-align: center;
  }
}

/* // my css  */
.navbar {
  font-family: system-ui;
}

html {
  overflow-x: hidden;
}

.login-form .input-group {
  height: 60px;
}

.login-form {
  font-family: system-ui;
}

.login-form .input-group-text {
  background-color: white !important;
}

.button-color {
  background-color: var(--red);
  color: white;
  border: none !important;
}

.button-color:hover {
  background-color: #000215 !important;
  color: white !important;
}

.back-color {
  color: #eab512;
}

.bg-c-blue {
  background-color: var(--red);
}

.bg-c-green {
  background-color: var(--green);
}

.login-form button {
  background-color: var(--green);
}

.min-height-60 {
  min-height: 160px;
}

.input-group .bi {
  color: var(--red);
}

.header-full {
  height: 50px;
  background-color: var(--black);
}

.sidebar .nav-item {
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.profile-sub-details {
  word-wrap: break-word;
}

.navbar-nav .nav-item a {
  font-size: 16px;
  padding-left: 10px;
  color: white !important;
  text-decoration: none;
}

table,
.card {
  font-family: system-ui;
}

.text-align {
  text-align: -webkit-center;
}

.form-width input {
  max-width: 40%;
}

.align-center {
  align-items: center;
}

.card {
  --bs-card-border-width: none !important;
}

.card-body .list-group-item {
  border: none !important;
}

.width-30 {
  width: 20%;
}

.custom-form .form-floating>.form-select {
  padding-top: 30px !important;
  height: calc(2.5rem + 2px) !important;
}

.bG-color {
  background: aliceblue;
}

.bg-white {
  background-color: white;
}

.box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.submit-buttom {
  background-color: var(--green);
  color: white;
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  background-color: var(--green);
  border-radius: 5px;
}

.table-img img {
  max-width: 30px;
}

table img {
  width: 100%;
}

.table-img {
  text-align: center;
}

.navbar-nav .nav-item .active {
  color: red !important;
}

@media (min-width: 320px) and (max-width: 600px) {
  .book-my-ticket {
    width: 100% !important;
  }

  .features-point p {
    font-size: 15px !important;
  }

  /* .navbar-nav .nav-item .active {
    background: none !important;
    padding: 15px 18px 15px 18px;
    margin-left: 0px !important;
  } */

  .mobile-center {
    text-align: center !important;
  }

  .mobile-margin {
    margin-top: 10px;
  }

  .form-width input {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .form-check .form-check-input {
    margin-left: -7px;
  }

  .form-check .form-check-label {
    margin-left: 10px;
  }

  .list-unstyled .mb-2 {
    text-align: center;
  }
}

.user-table p {
  margin-bottom: 0px !important;
}

/* my new css */

.settings .bi {
  padding-left: 5px;
}

.menu-bar .bi {
  padding-right: 5px;
}

.menu-bar ul li {
  margin: 0px 10px 0px 10px;
}

.content a {
  text-decoration: none;
}

.btn:hover {
  background-color: var(--red);
  color: white;
}

/* .navbar-nav .nav-item .active {
  background: var(--red);
  padding: 15px 18px 15px 18px;
  margin-left: 10px;
} */

.align-baseline {
  align-items: baseline !important;
}

.nav-item p {
  font-size: 15px;
  display: none;
}

.align-item {
  align-items: center;
}

.table-img {
  height: 100px;
}

.loginPanel {
  margin: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.reward-button {
  color: var(--white);
  padding: 4px 0px;
  border-radius: 0px 15px 15px 0px;
}

.tabs-radius {
  border-radius: 12px 12px 0px 0px;
}

.bg {
  background-color: #d7d5d5;
  /* background-image: url(./images/bg.jpg); */
  min-height: 100vh;
  background-size: cover;
}

.word-wrap-break {
  word-wrap: break-word;
}

.w-40 {
  width: 40%;
}

.sub-bg {
  background-image: url(./images/subscription-bg.jpg);
  min-height: 100vh;
  background-size: cover;
}

.w-35px {
  width: 35px;
}

.line-height-15 {
  line-height: 15px;
}

.w-40 {
  width: 40%;
}

@media only screen and (min-width: 600px) {
  .ticket-num {
    padding: 10px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar {
    display: none;
  }

  .board-num {
    padding: 3px 5px !important;
  }

  .side-spacing {
    padding: 0px 5px;
  }

  .board-spacing {
    padding: 10px 15px;
  }
}

.p-13 {
  font-size: 13px;
}

.price-strip {
  background: red;
  color: #fff !important;
  padding: 6px 0px;
  box-shadow: 0px 1px 5px 3px;
}

.price-strip h4 {
  font-size: 30px;
}

.features-point p {
  font-size: 18px;
}

.sidebar {
  height: calc(100vh - 50px);
  padding-top: 10px;
  background-color: var(--black);
  color: #fff;
  overflow: auto;
  position: fixed;
  top: 50px;
  left: 0;
  width: 220px;
  z-index: 1000;
}


.reward-tab {
  background-image: linear-gradient(270deg, #e9a528, #211500);
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  padding: 6px 0;
  text-align: center;
}

.counter-box {
  background: red;
  border-radius: 50%;
  box-shadow: 0 1px 5px 2px #000;
  color: #fff;
  padding: 15px 9px;
}

.cross-btn {
  background-color: red !important;
  color: var(--white);
}


.sidebar::-webkit-scrollbar {
  width: 10px;
  background: red;
}


.sidebar::-webkit-scrollbar-track {
  border-radius: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(25, 226, 35, 0.822);
}

.domain-field {
  background: green;
  margin: 0px;
  padding: 10px 10px 10px 0px;
  border: 1px solid #ccc;
  color: #fff;
}

.mobile-tab-btn i {
  font-size: 30px;
  background: green;
  padding: 0px 7px;
  color: #fff;
  border-radius: 0px 9px 9px 0px;
}

.moble-close-btn i {
  float: right;
  background: red;
  padding: 2px 7px 0px 7px;
  font-size: 21px;
  border-radius: 50%;
}

.book-my-ticket {
  position: fixed;
  bottom: 0;
  width: 84%;
}

.cursor-pointer {
  cursor: pointer;
}